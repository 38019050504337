<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGarden.online_adv_visit') }} {{ $t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <!-- fiscal year -->
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <b-form-group
                label-for="fiscal_year_id"
                >
                  <template v-slot:label>
                    {{$t('globalTrans.fiscal_year')}}
                  </template>
                  <b-form-select
                    plain
                    id="clone_info_id"
                    :options="fiscalYearList"
                    v-model="search.fiscal_year_id"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
          </b-col>
          <!-- garden -->
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Garden Name" vid="garden_id">
              <b-form-group
                label-for="garden_id">
                <template v-slot:label>
                  {{ $t('teaGardenConfig.garden_name') }}
                </template>
                <b-form-select
                  id="garden_id"
                  plain
                  v-model="search.garden_id"
                  :options="getGardenList"
                  :reduce="op => op.value"
                  label="text"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- from date -->
          <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="From Date" vid="from_date">
                <b-form-group
                    label-for="from_date"
                >
                  <template v-slot:label>
                    {{$t('globalTrans.from_date')}}
                  </template>
                  <date-picker
                    id="from_date"
                    v-model="search.from_date"
                    class="form-control"
                    :placeholder="$t('globalTrans.select_date')"
                  >
                  </date-picker>
                </b-form-group>
              </ValidationProvider>
          </b-col>
          <!-- to date -->
          <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="To Date" vid="to_date">
                <b-form-group
                    label-for="to_date"
                >
                  <template v-slot:label>
                    {{$t('globalTrans.to_date')}}
                  </template>
                  <date-picker
                    id="to_date"
                    v-model="search.to_date"
                    class="form-control"
                    :placeholder="$t('globalTrans.select_date')"
                  >
                  </date-picker>
                </b-form-group>
              </ValidationProvider>
          </b-col>
          <!-- status -->
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <ValidationProvider name="Status" vid="status" rules="">
              <b-form-group
                label-for="garden_id"
              >
                <template v-slot:label>
                  {{ $t('globalTrans.status') }}
                </template>
                <b-form-select
                  plain
                  v-model="search.status"
                  :options="statusList"
                  id="status"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGarden.online_adv_visit') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(garden_id)="data">
                      {{ getGardenName(data.item.garden_id) }}
                  </template>
                  <template v-slot:cell(status)="data">
                      {{ getStatusName(data.item.status) }}
                  </template>
                  <template v-slot:cell(tentative_date)="data">
                      <span v-if="data.item.tentative_date">{{data.item.tentative_date | dateFormat}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button class="moc-action-btn moc-view-btn" v-b-modal.modal-form-details @click="details(data.item)" :title="$t('globalTrans.view')">
                        <i class="ri-eye-line"></i>
                    </b-button>
                     <b-button v-if="data.item.status === 2 && !isGardenAdminCheckGardenId()" class="moc-action-btn moc-approve-btn" :title="$t('globalTrans.confirm')" v-b-modal.modal-visit-garden-confirm @click="visitGardenConfirmModalOpen(data.item.id)">
                      <i class="ri-shield-check-line"></i>
                    </b-button>
                    <a target="_blank" :href="teaGardenServiceBaseUrl + (isImage(data.item.attachment) ? 'storage/' : '') + data.item.attachment" v-if="data.item.status === 4 && data.item.attachment" class="moc-action-btn btn-primary" :title="$t('globalTrans.attachment')">
                      <i class="ri-file-line"></i>
                    </a>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <!-- visit garden confirm mdoal -->
   <b-modal id="modal-visit-garden-confirm" size="lg" :title="visitGardenConfirmTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <OnlineVisitConfirm :id="editItemId" :key="editItemId"/>
    </b-modal>
      <!-- details modal -->
    <b-modal id="modal-form-details" size="lg" :title="detailsFormTitle" hide-footer :ok-title="$t('globalTrans.close')"
        ok-only ok-variant="danger">
        <Details :id="editItemId" :key="editItemId" ref="details" />
    </b-modal>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { onlineAdvisoryVisitlList } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'
import OnlineVisitConfirm from './OnlineVisitConfirm'
import teaGardenService from '@/mixins/tea-garden-service'
// from garden advisory visit details
import Details from '../../../../garden/pages/advisory-visit/Details.vue'

export default {
  mixins: [ModalBaseMasterList, teaGardenService],
  components: {
    OnlineVisitConfirm,
    Details
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        search: {
          fiscal_year_id: this.$route.query.fiscal_year_id ? this.$route.query.fiscal_year_id : 0,
          status: this.$route.query.status ? this.$route.query.status : 0,
          limit: this.$route.query.limit ? this.$route.query.limit : 10,
          garden_id: 0
        },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '8%' } },
        { labels: 'teaGardenService.application_id', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'teaGardenConfig.garden_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '25%' } },
        { labels: 'teaGardenBtriService.tentative_date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
        { labels: 'globalTrans.status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '12%' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 7, thStyle: { width: '12%' } }
      ],
      items: [],
      viewitemId: 0,
      actions: {
        edit: true,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    currentLocal () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    getGardenList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'application_no' },
          { key: 'garden_id' },
          { key: 'tentative_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'application_no' },
          { key: 'garden_id' },
          { key: 'tentative_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    visitGardenConfirmTitle () {
      return (this.editItemId === 0) ? this.$t('teaGarden.online_adv_visit') + ' ' + this.$t('globalTrans.entry') : this.$t('teaGarden.online_adv_visit') + ' ' + this.$t('globalTrans.modify')
    },
    detailsFormTitle () {
      return this.$t('teaGarden.online_adv_visit') + ' ' + this.$t('globalTrans.details')
    },
    statusList () {
        const arr = [
          { value: 1, text: this.$t('globalTrans.draft'), text_en: this.$t('globalTrans.draft', 'en'), text_bn: this.$t('globalTrans.draft', 'bn') },
          { value: 2, text: this.$t('globalTrans.pending'), text_en: this.$t('globalTrans.pending', 'en'), text_bn: this.$t('globalTrans.pending', 'bn') },
          { value: 3, text: this.$t('globalTrans.confirm'), text_en: this.$t('globalTrans.confirm', 'en'), text_bn: this.$t('globalTrans.confirm', 'bn') },
          { value: 4, text: this.$t('globalTrans.visited'), text_en: this.$t('globalTrans.visited', 'en'), text_bn: this.$t('globalTrans.visited', 'bn') }
        ]
        return arr
      }
  },
  methods: {
    visitGardenConfirmModalOpen (id) {
        this.editItemId = id
    },
    details (item) {
      this.editItemId = item.id
    },
    searchData () {
      this.loadData()
    },
    getFiscalYear (yearId) {
        const cateObj = this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).find(item => item.value === yearId)
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        }
    },
    getStatusName (status) {
      const obj = this.statusList.find(item => item.value === parseInt(status))
      return this.currentLocal === 'en' ? obj?.text_en : obj?.text_bn
    },
    getGardenName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
      return this.currentLocal === 'en' ? obj?.text_en : obj?.text_bn
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, onlineAdvisoryVisitlList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    }
  }
}
</script>
